.navbar{
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    background: #FFFFFF;
    color: black;
}
.navbar_cart_quantity{
    color: rgb(243, 243, 237);
    position: relative;
}
.navbar_cart_quantity_number{
    background: transparent;
    text-align: center;
    width: 30px;
    border-radius: 100px;
    background: rgb(218, 107, 5);
    position: absolute;
    top: -38%;
    left: 61%;;
}

.navbar_iimg{
    width: 20px;
    margin-right: 20px;
}

