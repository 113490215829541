.App, body, html{
    margin: 0;
    padding: 0;
    background: #E4E7EA;
    height: 100vh;
    font-family: 'Poppins', sans-serif;
}

a{
    text-decoration: none;
    color: black;
}

.btn:hover {
    color: #fff;
}