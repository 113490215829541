.dashboard{
    margin-top: 185px;
    margin-left: 150px;
    height: auto;
    background: rgba(202, 194, 194, 0.151);
    /* border: 1px solid black; */
    border-radius: 2rem;
    margin-bottom: 20px;
}

.dashboard > form {
    margin-top: 50px;
    margin-right: 10px;
    margin-left: 10px   ;
    margin-bottom: 50px;
}
