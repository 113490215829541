.show-grid [class^="col-"] {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #171a29;
  display: flex;
  align-items: center;
  justify-content: center;
}

.restaurantfood,
.searchbar {
  margin-top: 5px;
}
.restaurantfood_type,
.restaurantfood_description {
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 1rem;
  white-space: nowrap;
  color: inherit;
  opacity: 0.7;
  font-size: 15px;
  max-width: 538px;
  color: white;
}
.restaurantfood_name {
  color: white;
  padding: 1rem;
  max-width: 538px;
  margin: 0;
  padding: 1rem;
  font-weight: 400;
  font-size: 32px;
}
._2iUp9:first-child {
  padding-left: 0;
  padding-right: 35px;
}
._2iUp9 {
  padding: 0 35px;
  border-right: 1px solid hsla(0, 0%, 100%, 0.2);
}
._2l3H5 {
  color: inherit;
  font-weight: 600;
  font-size: 16px;
}
._2n5YQ {
  margin-right: 6px;
  font-size: 12px;
  position: relative;
  top: -1px;
}
._1iYuU {
  text-transform: capitalize;
}
._1De48 {
  color: inherit;
  opacity: 0.7;
  font-weight: 400;
  font-size: 12px;
  margin-top: 5px;
}
._2FyFZ {
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: rgb(255, 255, 255);
  transform: translateZ(0);
  transition: transform 0.2s ease-in-out;
  border: 1px solid #bec0c8;
  position: relative;
}
.QWCzK {
  background-color: rgb(0, 0, 0);
  color: inherit;
  font-size: 20px;
  font-weight: 500;
  background-color: #171a29;
  display: inline-block;
  padding-right: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
  position: absolute;
  top: -13px;
  left: -6px;
}
._3F2Nk {
  padding: 31px 40px 25px 25px;
}
.DM5zR {
  z-index: -1;
  line-height: 17px;
  display: -ms-flexbox;
  flex-direction: column;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 16px;
}
._3lvLZ {
  -webkit-box-orient: vertical;
}
._26GkL {
  margin-right: 10px;
  font-size: 22px;
  -ms-flex-item-align: baseline;
  align-self: baseline;
}
._3lvLZ {
  word-break: break-word;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: 14px;
  color: inherit;
  opacity: .9;
  line-height: 1.2;
  font-weight: 500;
  padding: 1rem;
}
.restaurantfood_content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  top: 30%;
  gap: 50px;
  background: rgba(17, 8, 8, 0.877);
  box-shadow: 5px 0px 5px rgb(119, 108, 108);
  padding: 10px;
  color: white;
}
.restaurantfood_details {
  padding: 1rem;
}
.restaurantfood_content > img {
  margin-left: 10px;
  padding: 10px;
}
.restaurantfood_offer {
  display: grid;
  /* margin-top: 10px; */
  margin-right: 10px;
  padding: 1rem;
  border: solid;
  height: 20vh;
  border-width: thin;
  color: white;
}
.restaurantfood_offer_ {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}
.restaurantfood_offer_ > div > span {
  margin-left: 10px;
}
.restaurantfood_add_more_product {
  position: absolute;
  top: 35%;
  left: 75%;
}
.image > img {
  width: 18rem;
}

@media only screen and (max-width: 890px) {
  .restaurantfood_content {
    display: grid;
    grid-template-columns: 1fr;
  }
  .restaurantfood_offer {
    width: 100%;
  }
  .image > img {
    width: 100%;
  }
}
