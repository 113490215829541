.dashboard__{
    margin-top: 150px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 0;
    flex-grow: 1;
    min-height: calc(100vh - 4.5rem)    
}
.dashboard_title{
    margin-top: -45px;
}
.dashboard_title h2{
    font-size: 36px;
    font-weight: 300;
}
.dashboard_container{
    margin-top: 30px;
}
.small-box {
    border-radius: 0.25rem;
    box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);
    display: block;
    margin-bottom: 20px;
    position: relative;
}

.small-box>.inner {
    padding: 10px;
}

.small-box > h3, .small-box > p {
    z-index: 5;
}

.small-box > h3 {
    /* font-size: 2.2rem; */
    font-weight: 700;
    margin: 0 0 10px;
    padding: 0;
    white-space: nowrap;
}

.small-box p {
    font-size: 1rem;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}
.small-box>.small-box-footer {
    background-color: rgba(0,0,0,.1);
    color: rgba(255,255,255,.8);
    display: block;
    padding: 3px 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    z-index: 10;
}
.icon>i.ion {
    font-size: 70px;
    top: 20px;
}

.small-box .icon {
    color: rgba(0,0,0,.15);
    z-index: 0;
}                                   

.res_icon{
    color: rgba(0,0,0,.3)   
}