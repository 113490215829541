.userlogin{
  margin-top: 10%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.card0 {
  box-shadow: 0px 4px 8px 0px #757575;
  border-radius: 0px;
  align-items: center;
  width: 80%;
  padding: 50px;
}

.card2 {
  margin: 0px 40px;
  width: 45%;
}

.image {
  width: 660px;
  height: 380px;
  border-radius: 30px;
}

.border-line {
  border-right: 1px solid #EEEEEE;
}

.text-sm {
  font-size: 14px !important;
}

::placeholder {
  color: #BDBDBD;
  opacity: 1;
  font-weight: 300
}

:-ms-input-placeholder {
  color: #BDBDBD;
  font-weight: 300
}

::-ms-input-placeholder {
  color: #BDBDBD;
  font-weight: 300
}

input, textarea {
  padding: 10px 12px 10px 12px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  margin-bottom: 5px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  color: #2C3E50;
  font-size: 14px;
  letter-spacing: 1px;
}

input:focus, textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #304FFE;
  outline-width: 0;
}

button:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline-width: 0;
}

a {
  color: inherit;
  cursor: pointer;
}

.btn-blue {
  background-color: #1A237E;
  width: 150px;
  color: #fff;
  border-radius: 2px;
}


.bg-blue {
  color: #fff;
  background-color: #1A237E;
}

@media screen and (max-width: 991px) {
  .logo {
      margin-left: 0px;
  }

  .image {
      width: 300px;
      height: 220px;
  }

  .border-line {
      border-right: none;
  }

  .card2 {
      border-top: 1px solid #EEEEEE !important;
      margin: 0px 15px;
  }
}