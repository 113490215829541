.cart {
  margin-top: 50px;
  width: 20%;
  position: absolute;
  background: #c8f5f0;
  top: 0%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  right: 1%;
  border-radius: 1%;
  z-index: 10000;
  transition: 0.8s all;
}
.cart_food_items {
  display: flex;
  padding: 10px;
  justify-content: space-between;
}
.cart_restaurant_title,
.cart_bill_details {
  padding: 10px;
}
.cart_restaurant_title > .cart_food_title {
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.cart_food_title{
  display: flex;
  justify-content: center;
  text-align: center;
}
.cart-total,
.cart_deliver_charge,
.cart_total_pay {
  display: flex;
  justify-content: space-between;
}
.cart_food_price{
  justify-content: center;
  text-align: center;
}
.cart_pay {
  margin-top: 30px;
  text-align: center;
}
.foodlist_cart_button {
  display: flex;
  width: 5vw;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.quantity {
  font-weight: 500;
  margin-right: 10px;
  margin-left: 10px;
}
.plus, .minus {
  background: #c8f5f0;
  font-size: 20px;
}

@media only screen and (max-width: 994px) {
  .cart{
    width: 35%;
  }
}



@media only screen and (max-width: 710px) {
  .cart{
    width: 45%;
  }
}




@media only screen and (min-width: 240px) and (max-width: 460px) {
  .cart{
    width: 65%;
  }
  .cart_pay > a{  
    width: 100%;
  }
}

