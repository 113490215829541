.showData{
    margin-top: 185px;
    width:100% ;
    margin-left: 150px;
}

.show_data_button{
    display: flex;
    flex-direction: row;
}

