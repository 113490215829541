@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");
:root {
  --header-height: 3rem;
  --nav-width: 68px;
  --first-color: #444055;
  --first-color-light: #afa5d9;
  --white-color: #f7f6fb;
  --body-font: "Nunito", sans-serif;
  --normal-font-size: 1rem;
  --z-fixed: 100;
}
*,
::before,
::after {
  box-sizing: border-box;
}
a {
  text-decoration: none;
}

.dashboard_home {
  width: 100%;
  margin-top: 50;
  height: 100vh;
  display: flex;

  /* border: 1px solid black; */
}
.dashboard_home ul > li {
  list-style-type: none;
}
.sidebar__ {
  flex: 0.4;
  height: 100vh;
}

.dashboard_content {
  width: 60vw;
}
@media (min-width: 576px) {
  .h-sm-100 {
    height: 100%;
  }
}
._show {
  width: 285px;
  height: 100vh;
  min-height: 100vh;
  background-color: var(--first-color);
  /* height: auto; */
  /* position: fixed;
  left: 0;
  right: 0;
  min-height: 100vh;
  overflow: auto;
  transition: 0.5s;
  z-index: var(--z-fixed); */
}
._show > span {
  color: white;
}
.header {
  width: 100%;
  height: var(--header-height);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: var(--white-color);
  z-index: var(--z-fixed);
  transition: 0.5s;
}

.header_img {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
}
.header_img img {
  width: 40px;
}
.header_toggle {
  color: var(--first-color);
  font-size: 1.5rem;
  cursor: pointer;
}

.header_toggle > svg {
  display: none;
}
.nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.nav_logo,
.nav_link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
}
.nav_logo {
  margin-bottom: 2rem;
}
.nav_logo-icon {
  font-size: 1.25rem;
  color: var(--white-color);
}
.nav_logo-name {
  color: var(--white-color);
  font-weight: 700;
}
.nav_link {
  position: relative;
  color: var(--first-color-light);
  margin-bottom: 1.5rem;
  transition: 0.3s;
}
.nav_link:hover {
  color: var(--white-color);
}
.nav_icon {
  font-size: 1.25rem;
}
.nav_list > ul > li {
  list-style: none;
}
.active {
  color: var(--white-color);
}
.height-100 {
  height: 100vh;
}

@media screen and (max-width: 768px) {
  .header {
    height: calc(var(--header-height) + 1rem);
    padding: 0 2rem 0 calc(var(--nav-width) + 2rem);
  }

  .header_img {
    width: 40px;
    height: 40px;
  }
  .header_img img {
    width: 45px;
  }
  .header_toggle {
    color: var(--first-color);
    font-size: 1.5rem;
    cursor: pointer;
  }
  ._show {
    display: none;
  }
  .header_toggle > svg {
    display: block;
    float: left;
  }
  .displayHide {
    width: 270px;
    position: fixed;
    left: 0;
    right: 0;
    margin-top: 65px;
    min-height: 100vh;
    overflow: auto;
    height: 100vh;
    background-color: var(--first-color);
    transition: 0.5s;
    z-index: var(--z-fixed);
  }
  .l-navbar {
    left: 0;
    padding: 1rem 1rem 0 0;
  }
}
