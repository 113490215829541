.login{
    margin-top: 80px;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.signin_title > p {
    margin-bottom:20px ;
    font-size: 45px;
    font-weight: 400;
}
.form-outline > input {
    width: 25vw;
}
