/* .restaurantlist {
  width: 100%;
  margin-top: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
} */
.card {
  width: 100%;
  border-radius: 10px;
  border: 1px solid transparent;
  background: rgb(250, 246, 246);
}
.img-card-section {
  height: 20vh;
  display: flex;
  justify-content: center;
}
.img-card-section img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
}

.card-body {
  display: flex;
  height: auto;
  flex-direction: column;
}
.card-text {
  height: auto;
  overflow: hidden;
}
.order_now {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
}
.order_now > a {
  background: #ff6348;
  width: 80%;
  outline: none;
  border: none;
}
.order_now :hover {
  background: #d16a58 !important;
}
.rating {
  margin-top: 10px;
  margin-left: 40px;
}
.no_data {
  position: absolute;
  top: 50%;
  left: 95%;
  transform: translate(-50%, -50%);
}
.no_data_title {
  color: red;
  font-size: 22px;
}

@media only screen and (max-width: 890px) {
  .restaurantlist {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 727px) {
  .restaurantlist {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 180px) and (max-device-width: 460px) {
  .restaurantlist {
    display: grid;
    grid-template-columns: 1fr;
  }
}
