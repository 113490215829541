
.foodlist_content{
    display: flex;
    margin-top: 10px;
    flex-direction: column;
}
.foodlist_foodtitle{
    font-size: 22px;
    font-weight: 600;
}
.foodlist_price{
    font-size: 18px;
    font-weight: 500;
}
.foodlist_description{
    font-size: 16px;
    font-weight: 100;
}
.foodlist_bestseller{
    justify-content: center;
}
.foodlist_bestseller > img{
  margin-right: 10px ;
}
.foodlist_bestseller > span{
    font-size: 16px;
}
.foodlist_cart_button{
    display: flex;
    justify-content: space-between;
    width: 80px;
    border: 1px solid black; 
}
.foodlist_cart_button > button{
    border: none;
}
.foodlist_addcart_btn{
    background: rgb(20, 124, 6);
    color: white;
    font-size: 1rem;
}
.no_product{
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.no_product_title{
    color: red;
    font-size: 22px;
}
.main-product-list {
    display: flex;
    flex-direction: row;
}
.product-img{
    position: relative;
    margin-right: 2rem;
    margin-bottom: 0px;
    flex-shrink: 0;
}
.foodlist_image {
    position: relative;
    max-width: 100%;
    width: 13rem;
    height: 13rem;
    overflow: hidden;
    border-radius: 0.8rem;
}
.foodList_image_bg {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    background: linear-gradient(to right, rgb(248, 248, 248) 0%, rgb(255, 255, 255) 10%, rgb(248, 248, 248) 40%, rgb(248, 248, 248) 100%) no-repeat rgb(248, 248, 248);
    opacity: 0;
    transition: opacity 0.25s ease-out 0s;
    will-change: opacity;
    border-radius: inherit;
    animation: 1.5s linear 0s infinite normal forwards running foodimg;
}
.product-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: none;
    opacity: 1;
    will-change: transform, opacity;
    border-radius: inherit;
    filter: unset;
    transition: opacity 0.25s ease 0s, transform 0.25s ease 0s;
}
@keyframes foodimg {
    0% {
        background-position: -80vw 0px;
    }
    100% {
        background-position: 80vw 0px;
    }
  }
@media only screen and (max-width: 890px) {
    .foodlist_content > div > button{
        width: 20vw;
    }
  }
  
  @media only screen and (max-width: 420px) {
    .foodlist{
        display: flex;
        grid-template-rows: 1fr;
        flex-direction: column-reverse;
    }
    .foodlist_content > div > button{
        width: 50vw;
    }
  }
  